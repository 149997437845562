// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-colors-index-js": () => import("./../../../src/pages/colors/index.js" /* webpackChunkName: "component---src-pages-colors-index-js" */),
  "component---src-pages-dev-index-js": () => import("./../../../src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-dev-web-index-js": () => import("./../../../src/pages/dev/web/index.js" /* webpackChunkName: "component---src-pages-dev-web-index-js" */),
  "component---src-pages-games-index-js": () => import("./../../../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-games-poe-index-js": () => import("./../../../src/pages/games/POE/index.js" /* webpackChunkName: "component---src-pages-games-poe-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pn-p-dn-d-index-js": () => import("./../../../src/pages/PnP/DnD/index.js" /* webpackChunkName: "component---src-pages-pn-p-dn-d-index-js" */),
  "component---src-pages-pn-p-index-js": () => import("./../../../src/pages/PnP/index.js" /* webpackChunkName: "component---src-pages-pn-p-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

